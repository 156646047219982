import React from "react";
import Project, { ProjectType } from "./Project";

const innershine =
  require("../../images/inner-shine-mindfulness-journal.png").default;
const teacherPlanner = require("../../images/teacher-planner.jpeg").default;
const bestTeacher = require("../../images/the-best-teacher.jpeg").default;

const personalProjects: ProjectType[] = [
  {
    title: "Leksokefalia",
    type: "personal",
    description:
      "During the hype of the game Wordle, I realised that I would like to play the game in Greek. Over a weekend, I dedicated my evenings on this project. Since then, I've been playing it daily.",
    links: [{ link: "https://leksokefalia.bakedbird.com", text: "Play now" }],
  },
  {
    title: "Kikiriki",
    type: "personal",
    description:
      "Kikiriki is an online alarm clock. Users can set multiple alarms and choose from different sounds. I'm a heavy sleeper and kikiriki was a way to ensure I always wake up on time.",
    // links: [{ link: "https://kikiriki.bakedbird.com/", text: "View site" }],
  },
  {
    title: "Email signature",
    type: "personal",
    description:
      "I created this app where users can generate their own email signature. There are many such tools but most require payment. I created this free one for students of my email signature course.",
    links: [
      { link: "https://email-signature.bakedbird.com/", text: "View site" },
    ],
  },
  {
    title: "YT giveaway",
    type: "personal",
    description:
      "There are many tools to handle giveaways on YouTube, but most of them are somewhat complicated. This tool is created to be straightforward with limited options.",
    // links: [{ link: "https://ytgiveaway.bakedbird.com/", text: "View site" }],
  },
];
const professionalProjects: ProjectType[] = [
  {
    title: "trivago",
    type: "current",
    description:
      "I joined the team of trivago in late 2020. Since then, I've had the opportunity to get my hands and work on various internal projects. Trivago is one of the major metasearch engines for accommodations.",
    position: "Sr. front end developer",
    links: [{ link: "http://trivago.com/", text: "View site" }],
  },
  {
    title: "Belvilla - OYO",
    type: "professional",
    description:
      "While working at Code Seed, I participated in various internal projects for Belvilla - OYO vacation homes. My role was both a senior front end developer as well as stakeholder in RnD projects.",
    position: "Sr. front end developer @Code Seed",
    links: [{ link: "https://www.belvilla.com/", text: "View site" }],
  },
  {
    title: "Anaplirotes",
    type: "professional",
    description:
      "Anaplirotes is a mobile application (Android, iOs) that shows associate professors their placements. The app bridged the gap between the professors and enormous excel lists from the ministry of education",
    position: "Sr. front end developer @Code Seed",
    links: [
      {
        link: "https://play.google.com/store/apps/details?id=gr.thanpa.topothetisi.anapliroton&hl=el",
        text: "Android",
      },
      {
        link: "https://apps.apple.com/us/app/%CF%84%CE%BF%CF%80%CE%BF%CE%B8%CE%AD%CF%84%CE%B7%CF%83%CE%B7-%CE%B1%CE%BD%CE%B1%CF%80%CE%BB%CE%B7%CF%81%CF%89%CF%84%CF%8E%CE%BD/id1448485045",
        text: "iOs",
      },
    ],
  },
  {
    title: "PolarApp",
    type: "professional",
    description:
      "With thousands of photos getting lost on our phones, with PolarApp users can upload photos from their mobile phone and get them printed and devlievered to their doorstep in just a few days.",
    position: "Sr. front end developer @Code Seed",
    links: [
      {
        link: "https://play.google.com/store/apps/details?id=gr.polarapp.polarapp",
        text: "Android",
      },
      {
        link: "https://apps.apple.com/gr/app/polarapp/id1343814041",
        text: "iOs",
      },
    ],
  },
];

const Projects = () => {
  return (
    <div>
      <div id="projects" className="container p-3 mx-auto mt-6 mb-16">
        <div className="md:w-3/5 lg:w-2/5 mb-6">
          <h2 className="font-display text-3xl mb-3">Projects highlight</h2>
          <p className="text-lg mb-3">
            I take pride on all projects I have worked on. Each one has been a
            unique experience that enabled me to learn and grow while providing
            solutions and value to each client and end-user. Below is some of my
            latest work.
          </p>
        </div>

        {/* Personal projects */}
        <div className="mb-10">
          <h3 className="text-center font-display text-2xl mb-3">
            Personal projects
          </h3>
          <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-6">
            {personalProjects.map((item) => (
              <Project {...item} />
            ))}
          </div>
        </div>

        {/* Professional projects */}
        <div className="">
          <h3 className="text-center font-display text-2xl mb-3">
            Professional projects
          </h3>
          <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-6">
            {professionalProjects.map((item) => (
              <Project {...item} />
            ))}
          </div>
        </div>
      </div>
      {/* Publications */}
      <div id="publications" className="bg-amber-200 py-12 px-3">
        <div className="container mx-auto">
          <div className="md:w-3/5 lg:w-2/5 mb-6">
            <h2 className="font-display text-3xl mb-3">Publications</h2>
            <p className="text-lg mb-3">
              My curiosity spans beyond technology. In the spirit of learning
              about the publishing world and implement solutions to different
              problems, I ended up publishing some prints myself.
            </p>
          </div>
          <div className="grid lg:grid-cols-2 gap-6">
            <div className="bg-slate-700 text-slate-100 rounded-md flex flex-col sm:flex-row items-center p-3">
              <img className="w-2/5" src={innershine} alt="" />
              <div className="sm:w-3/5">
                <h2 className="text-center sm:text-left text-xl font-semibold mb-3">
                  Inner Shine: Your mindfulness journal
                </h2>
                <p className="mb-2">
                  After keeping different kinds of journals for years, I put
                  together a gratitude journal by gathering the knowledge I have
                  gotten through my own experience.
                </p>
                <p className="mb-4">
                  Inner Shine is a 20-week gratitude journal that has been built
                  with you in mind. It helps you find inner peace, be happier
                  and eliminate stress by guiding you through five strong
                  principles! Start building a habit of action and achieve your
                  goals - today.
                </p>
                <a
                  target="_blank"
                  className="underline text-sky-500 text-lg"
                  href="https://www.amazon.com/dp/1709719702"
                >
                  View on Amazon
                </a>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-slate-400 py-6 px-3 rounded-md text-slate-800">
                <img
                  className="w-2/5 mx-auto rounded-md"
                  src={teacherPlanner}
                  alt=""
                />
                <h2 className="text-xl text-center mt-2 font-semibold mb-3">
                  Teacher's planner 2020-21
                </h2>
                <p className="mb-2">
                  A daily lesson planner and calendar for teachers, for the
                  school year 2020 - 2021. My wife is a teacher, and after
                  listening to her needs, going through her daily notes and
                  lesson schedule, I created this fully- featured planner for
                  her.
                </p>
                <a
                  target="_blank"
                  className="underline text-sky-900"
                  href="https://www.amazon.com/dp/B08GB3KBPR"
                >
                  View on Amazon
                </a>
              </div>
              <div className="bg-slate-400 py-6 px-3 rounded-md text-slate-800">
                <img
                  className="w-2/5 mx-auto rounded-md"
                  src={bestTeacher}
                  alt=""
                />
                <h2 className="text-xl text-center mt-2 font-semibold mb-3">
                  The best teacher
                </h2>
                <p className="mb-2">
                  Besides lesson plans, it seems that teachers keep all sorts of
                  notes. This is a simple notebook I also created for my wife's
                  needs.
                </p>
                <a
                  target="_blank"
                  className="underline text-sky-900"
                  href="https://www.amazon.com/dp/B091J36FF4"
                >
                  View on Amazon
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
