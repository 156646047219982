import React from "react";

const me = require("../../images/me-big.jpeg").default;

const About = () => {
  return (
    <div
      id="about"
      className="container mx-auto py-14 px-3 flex border-b border-stone-200"
    >
      <div className="hidden w-2/5 pr-4 md:flex justify-center items-center">
        <div className="absolute h-80 w-64 lg:h-96 lg:w-72 rounded-lg bg-amber-50 rotate-6"></div>
        <div className="absolute h-80 w-64 lg:h-96 lg:w-72 rounded-lg bg-amber-100"></div>
        <div className="absolute h-80 w-64 lg:h-96 lg:w-72 rounded-lg bg-amber-200 -rotate-6"></div>
        <img
          src={me}
          alt="Eleftherios Psitopoulos"
          className="saturate-50 h-80 lg:h-96 -rotate-12 rounded-lg"
        />
      </div>
      <div className="md:w-3/5 flex flex-col justify-center">
        <h2 className="font-display text-3xl mb-3">About me</h2>
        <p className="text-lg mb-3">
          My name is Eleftherios Psitopoulos. I started learning how to program
          with C around the age of 12. One my first achievements was creating a
          RPG game that was played on the terminal. It was not something big,
          but it was at that moment I decided that technology will be a big part
          of my life. Learning how to use different programming languages and
          how to "think programmatically", I stumbled upon HTML and CSS. The
          concept of the internet and how it can connect people from all around
          the world fascinated me.
        </p>
        <p className="text-lg mb-3">
          Since then, I had the oportunity to work on various projects with a
          plethora of clients as a freelancer, as well as be part of highly
          motivated teams in international companies where my range of knowledge
          grew exponentially.
        </p>
        <p className="text-lg mb-3">
          Nowadays, I'm part of an entrepreneurial team within trivago. My role
          is senior front end developer and my responsibilities include ensuring
          high quality in the codebase, brainstorming for fresh ideas and a
          passion to constantly learn and grow.
        </p>
        <p className="text-lg mb-3">
          I strongly believe in teamwork and the wonders that can be achieved
          when we work together in trust of each other and proper feedback.
          Being lucky to be part of such teams, I've always had the feeling of
          achievemnt after work. The feeling of having made the world a little
          bit better through technology.
        </p>
      </div>
    </div>
  );
};

export default About;
