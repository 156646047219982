import React from "react";

const Testimonials = () => {
  return (
    <div>
      <div id="testimonials" className="container p-3 mx-auto my-16">
        <div className="md:w-3/5 lg:w-2/5 mb-6">
          <h2 className="font-display text-3xl mb-3">
            What they’ve said about me
          </h2>
          <p className="text-lg mb-10">
            Kind words certainly motivate you to move forward and upwards
            keeping a positive mindset, especially when those words come from
            wonderful teams you’ve worked with.
          </p>
        </div>

        <div className="md:w-3/5 mb-6 md:mb-0 align-top inline-block pr-6">
          <h3 className="text-2xl font-bold">Shreyas Joshi</h3>
          <span className="text-lg">Product Manager at OYO</span>
          <p className="mt-3">
            "...Attention to detail, execution prowess and sense of ownership is
            something which I have rarely seen in 90% of the engineering teams I
            have worked with, till date. Someone who takes initiative, is not
            afraid to go out of the comfort zone and take lead when others don't
            step up..."
          </p>
        </div>

        <div className="mb-6 md:mb-0 md:w-2/5 align-top inline-block">
          <h3 className="text-2xl font-bold">Rishabh Dassani</h3>
          <span className="text-lg">Product Owner at OYO</span>
          <p className="mt-3">
            "...Prompt, sharp and extremely good at his work, it was such a
            pleasure that I really wish our paths cross again in the future
            sometime..."
          </p>
        </div>

        <div className="mb-6 md:mb-0 md:w-2/6 align-top mt-6 pr-6 inline-block">
          <h3 className="text-2xl font-bold">Anton Kolinko</h3>
          <span className="text-lg">Tech lead at Belvilla</span>
          <p className="mt-3">
            "...Leffe is really great asset to any team, especially for team
            lead role. He is always there for you if you need help or
            guidance..."
          </p>
        </div>

        <div className="mb-6 md:mb-0 md:w-2/6 align-top mt-6 pr-6 inline-block">
          <h3 className="text-2xl font-bold">Liudmyla Brovkina</h3>
          <span className="text-lg">QA Engineer at Belvilla</span>
          <p className="mt-3">
            "...significantly contributes in product development. His
            initiative, creativity and readiness to help surprised me and the
            whole team many times...."
          </p>
        </div>

        <div className="mb-6 md:mb-0 md:w-2/6 align-top mt-6 inline-block">
          <h3 className="text-2xl font-bold">Mark van Lit</h3>
          <span className="text-lg">
            Senior Front end Developer at Belvilla
          </span>
          <p className="mt-3">
            "...A highly skilled front end developer who will take your project
            to the next level!..."
          </p>
        </div>
      </div>

      <div className="bg-slate-300 p-10 text-center text-2xl">
        <p>
          Those were some excerpts from recommendations on my LinkedIn profile.
        </p>
        <p>Feel free to read the whole text on there.</p>
        <a
          className="inline-block rounded-full mt-6 text-slate-600 bg-amber-200 py-4 px-6"
          href="https://linkedin.com/in/elpsitopoulos"
          target="_blank"
        >
          Read all recommendations
        </a>
      </div>
    </div>
  );
};

export default Testimonials;
