import React from "react";

type Link = { link?: string; text: string };

export type ProjectType = {
  title: string;
  type: "personal" | "professional" | "current";
  position?: string;
  description: string;
  links?: Link[];
};

const Project = ({
  title,
  description,
  position,
  links,
  type,
}: ProjectType) => {
  return (
    <div className="rounded-md border">
      <div
        className={`flex justify-center items-center flex-col py-14 ${
          type === "personal"
            ? "bg-stone-300"
            : type === "professional"
            ? "bg-amber-200"
            : "bg-amber-300"
        }`}
      >
        <h4 className="font-display text-2xl">{title}</h4>
        {position && <p>{position}</p>}
      </div>
      <div className="px-3 py-6">
        <p className="mb-3">{description}</p>
        {links &&
          links.map((link) =>
            link.link ? (
              <a
                target={"_blank"}
                className="text-sky-600 underline mr-3"
                href={link.link}
              >
                {link.text}
              </a>
            ) : (
              <span>{link.text}</span>
            )
          )}
      </div>
    </div>
  );
};

export default Project;
