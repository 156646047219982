import React, { useState } from "react";

const { default: logo } = require("../images/bakedbird.svg");

const Header = () => {
  const [menuClass, setMenuClass] = useState("hidden");

  const toggleMenu = () => {
    if (window.innerWidth <= 767) {
      setMenuClass((p) => (p === "hidden" ? "block" : "hidden"));
    } else if (menuClass !== "hidden") {
      setMenuClass("hidden");
    }
  };

  return (
    <div className="absolute w-full p-3 flex">
      <header className="flex flex-col md:flex-row items-center p-3 container rounded-xl bg-stone-50 mx-auto border-b-2 border-slate-100">
        <div className="flex w-full flex-1 md:w-auto items-center">
          <img
            src={logo}
            className="w-10 mr-2"
            alt="Bakedbird - Eleftherios Psitopoulos, senior front end developer"
          />
          <span className="text-2xl font-display font-bold">Bakedbird</span>

          <div onClick={toggleMenu} className="md:hidden ml-auto space-y-2">
            <div className="w-8 h-0.5 bg-gray-600"></div>
            <div className="w-6 h-0.5 bg-gray-600"></div>
            <div className="w-8 h-0.5 bg-gray-600"></div>
          </div>
        </div>

        <nav
          onClick={toggleMenu}
          className={`${menuClass} mt-4 md:mt-0 md:inline text-center`}
        >
          <a
            className="block md:inline text-2xl md:text-base mr-2 hover:text-amber-300"
            href="#about"
          >
            About me
          </a>
          <a
            className="block md:inline text-2xl md:text-base mr-2 hover:text-amber-300"
            href="#projects"
          >
            Projects
          </a>
          <a
            className="block md:inline text-2xl md:text-base mr-2 hover:text-amber-300"
            href="#publications"
          >
            Publications
          </a>
          <a
            className="block md:inline text-2xl md:text-base mr-2 hover:text-amber-300"
            href="#testimonials"
          >
            Testimonials
          </a>
          <a
            className="block md:inline text-2xl md:text-base mr-2 hover:text-amber-300"
            href="#contact"
          >
            Contact
          </a>
        </nav>
      </header>
    </div>
  );
};

export default Header;
