import React from "react";
import Header from "./Header";

import "../styles/styles.css";
import Footer from "./Footer";

type Props = { children: JSX.Element };

const Layout = (props: Props) => {
  return (
    <div className="bg-stone-50 min-h-screen flex flex-col text-stone-800">
      <Header />
      <div className="mb-auto">{props.children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
