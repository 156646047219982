import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import About from "./Home/About";
import Facts from "./Home/Facts";
import Jumbo from "./Home/Jumbo";
import Projects from "./Home/Projects";
import Testimonials from "./Home/Testimonials";

const HomePage = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Eleftherios Psitopoulos - Scrum master and senior front end developer
        </title>
        <meta
          name="description"
          content="Scrum master and senior front end developer."
        />
      </Helmet>
      <main>
        <Jumbo />
        {/* <WordRoll /> */}
        <About />
        <Projects />
        <Testimonials />
        <Facts />
      </main>
    </Layout>
  );
};

export default HomePage;
