import React from "react";

const Facts = () => {
  return (
    <div>
      <div className="container p-3 mx-auto my-16">
        <div className="md:w-3/5 lg:w-2/5 mb-6">
          <h2 className="font-display text-3xl mb-3">The more you know</h2>
          <p className="text-lg mb-10">
            Some fun facts about this website I wanted to share with you. If you
            wondered about those things, now you know.
          </p>
        </div>

        <div className="mb-6 md:mb-0 md:w-2/6 align-top mt-6 pr-6 inline-block">
          <h3 className="text-2xl">What is Bakedbird</h3>
          <p className="mt-3">
            Bakedbird, however weird it may sound when you first see it, is the
            literal translation of my last name, Psitopoulos, in English.
          </p>
        </div>

        <div className="mb-6 md:mb-0 md:w-2/6 align-top mt-6 pr-6 inline-block">
          <h3 className="text-2xl">The yellow colour</h3>
          <p className="mt-3">
            I chose yellow as the main colour of this website because of my
            canary bird from which I draw inspiration every day.
          </p>
        </div>

        <div className="mb-6 md:mb-0 md:w-2/6 align-top mt-6 inline-block">
          <h3 className="text-2xl">The logo</h3>
          <p className="mt-3">
            Just by combining the previous two facts, you can get where the logo
            comes from. In fact, it was originally blue for years, before I got
            the canary.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Facts;
