import React from "react";

const { default: logo } = require("../images/bakedbird.svg");

const Footer = () => {
  return (
    <footer id="contact" className="bg-slate-800 p-10">
      <div className="container mx-auto text-stone-50 flex flex-col items-center">
        <img
          src={logo}
          className="w-10 mb-4"
          alt="Bakedbird - Eleftherios Psitopoulos, senior front end developer"
        />
        <nav className="mb-4">
          <a
            className="mr-4"
            href="https://www.linkedin.com/in/elpsitopoulos/"
            target="_blank"
          >
            Linkedin
          </a>
          <a
            className="mr-4"
            href="https://www.instagram.com/leffesvensson/"
            target="_blank"
          >
            Instagram
          </a>
          <a
            className="mr-4"
            href="https://github.com/bakedbird"
            target="_blank"
          >
            Github
          </a>
          <a className="mr-4" href="https://twitter.com/lefpsi" target="_blank">
            Twitter
          </a>
          <a href="mailto:lefpsi@gmail.com" target="_blank">
            Email
          </a>
        </nav>

        <p>Made with care and 💛 by Psitopoulos Eleftherios</p>
      </div>
    </footer>
  );
};

export default Footer;
